import React from 'react'
import { css } from '@styled-system/css'
import Box from '../primitives/Box'
import Row from '../primitives/Row'
import Stack from '../primitives/Stack'
import { Quote } from './QuoteBlock'

type Props = {
  quote: Quote
  isDark?: boolean
}

export function SmallQuoteBlock(props: Props) {
  const { quote, isDark = false } = props
  const { quote: text, name, title, photo } = quote
  return (
    <Row
      space={24}
      padding={isDark ? 40 : 0}
      backgroundColor={isDark ? 'blockLight' : 'transparent'}
      borderStyle={isDark ? 'solid' : 'none'}
      borderRadius={24}
      borderColor="blockLightBorder"
    >
      <Stack space={24}>
        <Box
          font="medium"
          fontWeight={500}
          color={isDark ? 'white' : 'blue900'}
          css={css`
            &::before {
              content: '“';
              width: 10px;
              margin-left: -10px;
              display: inline-block;
            }
            &::after {
              content: '”';
              width: 20px;
              /* Prevent flowing to second row*/
              margin-right: -20px;
              display: inline-block;
            }
          `}
        >
          {text}
        </Box>
        <Row space={16} alignItems="center">
          <Box.img loading="lazy" width={64} height={64} src={photo} />
          <Stack position="relative">
            <Box
              font="small"
              fontWeight={700}
              color={isDark ? 'white' : 'black'}
            >
              {name}
            </Box>
            <Box font="small" color={isDark ? 'white' : 'black'}>
              {title}
            </Box>
          </Stack>
        </Row>
      </Stack>
    </Row>
  )
}
